<template>
  <div>
    <h1 class="heading">My Banners</h1>
    <div class="event_header" style="width:100%;margin-bottom:15px;">
      <div style="display: flex;justify-content: space-between;width:100%;">
        <div style="flex:1;margin-right:15px;">
          <v-select
              v-model="objectItem"
              hide-details
              :items="objects.list"
              item-value="id"
              item-text="name"
              return-object
              dense
              style="max-width: 300px"
              outlined
              @change="changeHotel"
          >
            <template v-slot:selection="{ item }">
              <div>{{ item.name }}</div>
              <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
              <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
              <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
            </template>
            <template v-slot:item="{ item }">
              <div>{{ item.name }}</div>
              <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
              <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
              <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
            </template>
          </v-select>
        </div>
        <div style="flex:1;display:flex;justify-content: flex-end">
          <v-btn color="primary" outlined dense @click="openChooseType" small
          >+ Add new banner</v-btn
          >
        </div>
      </div>
    </div>
    <div>
          <v-data-table
              class="table rooms__table elevation-1"
              :headers="headers"
              hide-default-footer
              no-data-text="You don't have banners yet, it's time to create them"
              :items="banners"
              item-key="id"
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="banners.length">
              <tr
                  v-for="item in items"
                  :key="item.id+item.sub_type"
              >
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  <img
                      :src="
                        item.image['en']
                          ? item.image['en']
                          : require('@/assets/none.png')
                      "
                      alt=""
                      style="max-height: 60px; max-width: 60px"
                  />
                </td>
                <td class="d-block d-sm-table-cell">
                  <v-layout
                      justify-center
                  >
                    {{item.name}}
                  </v-layout>
                </td>
                <td class="d-block d-sm-table-cell">
                  <v-layout justify-center style="text-transform: capitalize;">
                    {{item.sub_type}}
                  </v-layout>
                </td>
                <td class="d-block d-sm-table-cell">
                  <v-layout justify-center v-if="item.show_all_time">
                    All days
                  </v-layout>
                  <v-layout justify-center v-else>
                    {{ item.date_from }} - {{ item.date_to }}
                  </v-layout>
                </td>

                <td class="d-block d-sm-table-cell" style="text-align: center">
                  <v-chip
                      class="ma-2"
                      :color="item.is_active ? 'green' : 'red'"
                      text-color="white"
                      style="cursor: pointer"
                      @click="changeActive(item)"
                  >
                    {{ item.is_active ? "Active" : "Inactive" }}
                  </v-chip>
                </td>
                <td class="d-block d-sm-table-cell">
                  <v-layout justify-center>
                    <v-menu offset-y open-on-hover bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small outlined color="primary" v-bind="attrs" v-on="on">
                          <v-icon>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title style="cursor: pointer"
                                             @click="openLandingSettings(item)"
                          >
                            <span
                                style="cursor: pointer;"
                            >Open banner</span
                            >
                          </v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title style="cursor: pointer"
                                             @click="openEditBanner(item)"
                          >
                            <span
                                style="cursor: pointer;"
                                @click="openEditBanner(item)"
                            >Edit banner</span
                            >
                          </v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title style="cursor: pointer"
                                             @click="showPopupDelete(item)"
                          >
                            <span
                                style="cursor: pointer;color:red;"
                            >Delete banner</span
                            >
                          </v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-layout>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td class="d-block d-sm-table-cell"
                    colspan="6"
                    style="
                    height:85px;
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                >
                  You don't have banners yet, it's time to create them
                </td>
              </tr>
              </tbody>
            </template>
        </v-data-table>
    </div>
    <PopupSuccess ref="PopupSuccess" :title="successMessage"/>
    <PopupDelete ref="PopupDelete" @confirm="BannerDeleteConfirm" title="Are you sure about delete banner?"/>
    <v-dialog v-model="checkBannerType" max-width="600px" :retain-focus="false" eager>
      <v-card>
        <v-icon
            style="top: 10px;right: 10px;position: absolute;"
            class="close__modal"
            @click="checkBannerType = false"
        >mdi-close</v-icon
        >
        <v-card-title style="display:flex;justify-content: center;align-items: center;flex-direction:column;">
          <div>
            Add new banner
          </div>
          <div style="font-size:16px;font-weight: normal;">choose banner type:</div>
        </v-card-title>
        <v-card-text>
          <div style="display:flex;flex-direction:column;align-items:flex-start;justify-content: flex-start;padding-bottom:20px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <v-btn outlined small color="primary" @click="openAddBanner" style="margin-right:15px;">
                Modal
              </v-btn>
              <div>
                Over the main menu
              </div>
            </div>
            <div style="display: flex;align-items:center;margin-bottom:15px;">
              <v-btn outlined small color="primary" @click="openAddBannerIntro" style="margin-right:15px;">
                Intro
              </v-btn>
              <div>
                Instead of the main menu
              </div>
            </div>
            <div style="display:flex;align-items:center;">
              <v-btn outlined small color="primary" @click="openAddBannerBottom" style="margin-right:15px;">
                Bottom
              </v-btn>
              <div>
                Under the main menu
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PopupIntro type="create" :add-intro-banner-model="addIntroBannerModel" @addIntroBannerConfirm="addIntroBannerConfirm" :object-item="objectItem" @closeIntroAddBannerDialog="closeIntroAddBannerDialog" :add-intro-banner-dialog="addIntroBannerDialog" v-if="addIntroBannerDialog"/>
    <PopupIntro type="edit" :add-intro-banner-model="editIntroBannerModel" @addIntroBannerConfirm="editIntroBannerConfirm" :object-item="objectItem" @closeIntroAddBannerDialog="closeIntroEditBannerDialog" :add-intro-banner-dialog="editIntroBannerDialog" v-if="editIntroBannerDialog"/>
    <PopupBottomBanner :type="type" :objectItem="objectItem" :add-bottom-banner-dialog="addBottomBannerDialog" :add-bottom-banner-model="addBottomBannerModel" @closeBottomBannerDialog="closeBottomBanner"  v-if="addBottomBannerDialog"/>
    <PopupBanner :type="typeBanner" :objectItem="objectItem" :add-bottom-banner-dialog="addBannerDialog" :add-bottom-banner-model="addBannerModel" @closeBottomBannerDialog="closeBanner"  v-if="addBannerDialog"/>
  </div>
</template>

<script>
import store from "@/store";
import  {mapActions,mapState} from "vuex";
import titleMixin from "@/mixins/titleMixin";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue2-datepicker/index.css";
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: "Banners",
  mixins:[titleMixin],
  title(){
    return 'My banners';
  },
  components: {
    PopupBanner:() => import("@/components/Popup/PopupBanner"),
    PopupDelete:() => import("@/components/Popup/PopupDelete"),
    PopupSuccess:() => import("@/components/Popup/PopupSuccess"),
    PopupIntro:() => import("@/components/Popup/PopupIntro"),
    PopupBottomBanner:() => import("@/components/Popup/PopupBottomBanner")
  },
  data(){
    return{
      typeBanner:"Create",
      type:"Create",
      addBottomBannerModel:{
        sub_type:"bottom",
        name:"default name",
        show_all_time:0,
        is_active:1,
        room_categories:[],
        week:{
          0:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }
            ],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          1:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          2:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          3:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          4:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          5:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          6:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
        },
        show_all_week:1,
        date_model:"",
        date_to:null,
        date_from:null,
        dates:[],
        link:{en:""},
        video_link:{en:""},
        video:{en:null},
        type:0,
        rooms:[],
        segment_ids:[],
        image:{en:`${process.env.VUE_APP_LINK}/banner_default.jpg`},
        hotel_id:null,
        styles:{
          banner_video_icon_size:80,
          banner_video_icon_color:"#000000",
          banner_video_icon_color_hover:"#ffffff",
          width:100,
          height:150,
          show_per_day:1
        }
      },
      defaultBottomBannerModel:{
        show_all_time:0,
        room_categories:[],
        sub_type:"bottom",
        name:"default name",
        is_active:1,
        show_all_week:1,
        week:{
          0:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }
            ],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          1:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          2:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          3:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          4:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          5:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          6:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
        },
        date_model:"",
        date_to:null,
        date_from:null,
        dates:[],
        link:{en:""},
        video_link:{en:""},
        video:{en:null},
        type:0,
        rooms:[],
        segment_ids:[],
        image:{en:`${process.env.VUE_APP_LINK}/banner_default.jpg`},
        hotel_id:null,
        styles:{
          banner_video_icon_size:80,
          banner_video_icon_color:"#000000",
          banner_video_icon_color_hover:"#ffffff",
          width:100,
          height:150,
          show_per_day:1
        }
      },
      addBottomBannerDialog:false,
      editIntroBannerModel:null,
      addIntroBannerDialog:false,
      addIntroBannerModel:{
        show_all_time:0,
        room_categories:[],
        sub_type:"intro",
        hotel_id:null,
        name:"default name",
        show_all_week:1,
        week:{
          0:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }
            ],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          1:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          2:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          3:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          4:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          5:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          6:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
        },
        rooms:[],
        segment_ids:[],
        styles:{
          icon: "mdi-close-circle-outline",
          bg: "#ffffff",
          icon_color: "#ffffff",
          icon_size: 24,
          show_per_day:1
        },
        pro: {
          en:{
            top_img: `${process.env.VUE_APP_LINK}/banner_default.jpg`,
            logo_img: `${process.env.VUE_APP_LINK}/banner_default.jpg`,
            main_description: "<p ><strong>WELCOME</strong></p>",
            btn_next: "mdi-arrow-right-circle",
            text_next: "<p ><strong>SKIP</strong></p>",
          },
          logo_width: "100",
          logo_height: "100",
        },
        type: 0,
        show_time:5,
        image: {en:`${process.env.VUE_APP_LINK}/banner_default.jpg`},
        video: {en:null},
        date_model:"",
        is_active:1,
        repeat: 0,
        date_to:null,
        date_from:null,
        dates:[],
      },
      editIntroBannerDialog:false,
      checkBannerType:false,
      addBannerDialogTabs:0,
      editBannerDialogTabs:0,
      deleteBanner:null,
      playButtonColor:false,
      playButtonColorHover:false,
      exitIconColor:false,
      shortcuts: [
        {
          text: "Today",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Yesterday",
          onClick() {
            const start = new Date();
            const end = new Date();
            end.setTime(end.getTime() + 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 7 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 30 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "This month",
          onClick() {
            const start = new Date();
            const end = new Date();
            const firstDayCurrentMonth = new Date(
                start.getFullYear(),
                start.getMonth(),
                1
            );
            const lastDayCurrefntMonth = new Date(
                end.getFullYear(),
                end.getMonth() + 1,
                0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
        {
          text: "Last month",
          onClick() {
            const dateLast = new Date();
            const firstDayCurrentMonth = new Date(
                dateLast.getFullYear(),
                dateLast.getMonth() - 1,
                1
            );
            const lastDayCurrefntMonth = new Date(
                dateLast.getFullYear(),
                dateLast.getMonth(),
                0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
      ],
      defaultIntroBanner:{
        show_all_time:0,
        room_categories:[],
        sub_type:"intro",
        hotel_id:null,
        name:"default name",
        show_all_week:1,
        week:{
          0:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }
            ],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          1:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          2:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          3:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          4:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          5:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          6:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
        },
        rooms:[],
        segment_ids:[],
        styles:{
          icon: "mdi-close-circle-outline",
          bg: "#ffffff",
          icon_color: "#ffffff",
          icon_size: 24,
          show_per_day:1
        },
        pro: {
          en:{
            top_img: `${process.env.VUE_APP_LINK}/banner_default.jpg`,
            logo_img: `${process.env.VUE_APP_LINK}/banner_default.jpg`,
            main_description: "<p ><strong>WELCOME</strong></p>",
            btn_next: "mdi-arrow-right-circle",
            text_next: "<p ><strong>SKIP</strong></p>",
          },
          logo_width: "100",
          logo_height: "100",
        },
        type: 0,
        show_time:5,
        image: {en:`${process.env.VUE_APP_LINK}/banner_default.jpg`},
        video: {en:null},
        date_model:"",
        is_active:1,
        repeat: 0,
        date_to:null,
        date_from:null,
        dates:[],
      },
      headers: [
        { text: "Image", value: "image", align: "center", sortable: true },
        { text: "Name", value: "date", align: "center", sortable: true },
        { text: "Type", value: "type", align: "center", sortable: false },
        { text: "Date Period", align: "center" },
        { text: "Active", value:"is_active",align: "center" },
        { text: "Options", align: "center", sortable: false },
      ],
      icons:[
        'mdi-close',
        'mdi-close-box',
        'mdi-close-box-outline',
        'mdi-close-circle',
        'mdi-close-circle-outline',
        'mdi-close-octagon',
        'mdi-close-octagon-outline',
        'mdi-close-outline',
        'mdi-close-network'
      ],
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
      },
      objectItem: null,
      successMessage:"",
      addBannerDialog:false,
      editBannerDialog:false,
      addBannerModel:{
        show_all_time:0,
        room_categories:[],
        name:"default name",
        show_all_week:1,
        is_active:1,
        week:{
          0:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }
            ],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          1:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          2:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          3:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          4:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          5:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          6:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
        },
        date_model:"",
        date_to:null,
        date_from:null,
        dates:[],
        link:{en:""},
        sub_type:"modal",
        video_link:{en:""},
        video:{en:null},
        type:0,
        rooms:[],
        segment_ids:[],
        image:{en:`${process.env.VUE_APP_LINK}/banner_default.jpg`},
        hotel_id:null,
        styles:{
          banner_video_icon_size:80,
          banner_video_icon_color:"#000000",
          banner_video_icon_color_hover:"#ffffff",
          banner_icon_color:"#ffffff",
          banner_icon:"mdi-close-circle-outline",
          banner_close_icon_size:24,
          width:90,
          height:50,
          show_per_day:1
        }
      },
      defaultBannerModel:{
        show_all_time:0,
        room_categories:[],
        name:"default name",
        show_all_week:1,
        is_active:1,
        week:{
          0:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }
            ],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          1:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          2:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          3:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          4:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          5:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          6:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
        },
        date_model:"",
        date_to:null,
        date_from:null,
        dates:[],
        link:{en:""},
        sub_type:"modal",
        video_link:{en:""},
        video:{en:null},
        type:0,
        rooms:[],
        segment_ids:[],
        image:{en:`${process.env.VUE_APP_LINK}/banner_default.jpg`},
        hotel_id:null,
        styles:{
          banner_video_icon_size:80,
          banner_video_icon_color:"#000000",
          banner_video_icon_color_hover:"#ffffff",
          banner_icon_color:"#ffffff",
          banner_icon:"mdi-close-circle-outline",
          banner_close_icon_size:24,
          width:90,
          height:50,
          show_per_day:1
        }
      },
      editBannerModel:{
        show_all_time:0,
        room_categories:[],
        name:"default name",
        show_all_week:1,
        is_active:1,
        week:{
          0:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }
            ],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          1:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          2:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          3:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          4:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          5:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          6:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
        },
        date_model:"",
        date_to:null,
        date_from:null,
        dates:[],
        link:{en:""},
        sub_type:"modal",
        video_link:{en:""},
        video:{en:null},
        type:0,
        rooms:[],
        segment_ids:[],
        image:{en:`${process.env.VUE_APP_LINK}/banner_default.jpg`},
        hotel_id:null,
        styles:{
          banner_video_icon_size:80,
          banner_video_icon_color:"#000000",
          banner_video_icon_color_hover:"#ffffff",
          banner_icon_color:"#ffffff",
          banner_icon:"mdi-close-circle-outline",
          banner_close_icon_size:24,
          width:90,
          height:50,
          show_per_day:1
        }
      },
      days:[
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      bannerTypes:{
        1:"Image",
        2:"Image with link",
        3:"Video link",
        4:"Video"
      },
      selected_lang:"en"
    }
  },
  beforeRouteEnter(to, from, next) {
    if(!store.state.objects.list.length){
      store.dispatch('objects/getList')
          .then((data) => {
            next();
          }).catch(() => {
        next();
      })
    }else{
      next();
    }
  },
  computed:{
    ...mapState(["objects"]),
    ...mapState('banners',['bannerList']),
    ...mapState('intro',['introList']),
    ...mapState('bottomBanner',['bottomBannersList']),
    ...mapState("landing", ["selected_langs"]),
    ...mapState(['landing']),
    styleObjectPlayAdd() {
      return {
        "--color":
        this.addBannerModel.styles.banner_video_icon_color,
        "--color-hover": this.addBannerModel.styles.banner_video_icon_color_hover,
        width: this.addBannerModel.styles.banner_video_icon_size + "px",
        height: this.addBannerModel.styles.banner_video_icon_size + "px",
        left: `calc(50% - ${this.addBannerModel.styles.banner_video_icon_size / 2}px)`,
        top: `calc(50% - ${this.addBannerModel.styles.banner_video_icon_size / 2}px)`,
      };
    },
    styleObjectPlayEdit() {
      return {
        "--color":
        this.editBannerModel.styles.banner_video_icon_color,
        "--color-hover": this.editBannerModel.styles.banner_video_icon_color_hover,
        width: this.editBannerModel.styles.banner_video_icon_size + "px",
        height: this.editBannerModel.styles.banner_video_icon_size + "px",
        left: `calc(50% - ${this.editBannerModel.styles.banner_video_icon_size / 2}px)`,
        top: `calc(50% - ${this.editBannerModel.styles.banner_video_icon_size / 2}px)`,
      };
    },
    banners(){
      return [...this.bannerList,...this.introList,...this.bottomBannersList]
    }
  },
  methods:{
    ...mapActions('banners',['getBannerList','deleteOneBanner','editBanner','createBanner','editBanner']),
    ...mapActions('intro',['getIntroBanners','putIntroBanner','deleteIntroBanner']),
    ...mapActions('bottomBanner',['getBottomBanners','putBottomBanner','deleteBottomBanner']),
    ...mapActions('rooms',['getRoomCategories']),
    closeBanner(){
      this.addBannerDialog = false
    },
    closeBottomBanner(){
      this.addBottomBannerDialog = false;
    },
    openEditBottom(item){
      this.type = "Edit"
      this.addBottomBannerModel = JSON.parse(JSON.stringify(item));
      this.addBottomBannerDialog = true;
    },
    openAddBannerBottom(){
      this.type = "Create"
      this.addBottomBannerModel = JSON.parse(JSON.stringify(this.defaultBottomBannerModel));
      this.checkBannerType = false;
      this.addBottomBannerDialog = true;
    },
    closeIntroAddBannerDialog(){
      this.addIntroBannerDialog = false;
    },
    async addIntroBannerConfirm(){
      this.successMessage = "You intro banner successfully created!";
      this.$refs.PopupSuccess.open();
      this.addIntroBannerDialog = false;
    },
    openAddBannerIntro(){
      this.addIntroBannerModel = JSON.parse(JSON.stringify(this.defaultIntroBanner))
      this.checkBannerType = false;
      this.addIntroBannerDialog = true;
    },
    openEditIntro(item){
      this.editIntroBannerModel = JSON.parse(JSON.stringify(item));
      this.editIntroBannerDialog = true;
    },
    closeIntroEditBannerDialog(){
      this.editIntroBannerDialog = false;
    },
    editIntroBannerConfirm(){
      this.successMessage = "You intro banner successfully edited!";
      this.$refs.PopupSuccess.open();
      this.editIntroBannerDialog = false;
    },
    openChooseType(){
      this.checkBannerType = true;
    },
    async openLandingSettings(item){
      if(item.sub_type === 'modal'){
        await this.$router.push(`/constructor/${this.objectItem.id}?bannerID=${item.id}`)
      }
      if(item.sub_type === 'intro'){
        await this.$router.push(`/constructor/${this.objectItem.id}?introID=${item.id}`)
      }
      if(item.sub_type === 'bottom'){
        await this.$router.push(`/constructor/${this.objectItem.id}?bottomBannerID=${item.id}`);
      }
    },
    formateDate(date){
      return this.$moment(date).format('DD.MM.YYYY');
    },
    mountEditDropzone(){
      this.$refs.uploadEditBanner.dropzone.removeAllFiles();
      if(this.editBannerModel.image){
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          ...this.editBannerModel,
        };
        this.$refs.uploadEditBanner.manuallyAddFile(
            mockFile,
            this.editBannerModel.image
        );
        this.$refs.uploadEditBanner.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =
            this.editBannerModel.image;
        this.$refs.uploadEditBanner.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadEditBanner.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.$refs.uploadEditBanner.dropzone.emit("complete", mockFile);
      }
    },
    async saveNewBanner(){
      this.addBannerModel.hotel_id = +this.objectItem.id;
      await this.createBanner(this.addBannerModel);
      this.successMessage = 'New banner created successfully!';
      this.addBannerDialog = false;
      this.$refs.PopupSuccess.open();
    },
    openLinkAddBanner(link){
      if(link){
        window.open(link,'_blank')
      }
    },
    closeEditBannerDialog(){
      this.editBannerDialog = false;
    },
    async saveEditBanner(){
      await this.editBanner(JSON.parse(JSON.stringify(this.editBannerModel)));
      this.successMessage = 'You banner edited successfully!';
      this.editBannerDialog = false;
      this.$refs.PopupSuccess.open();
    },
    openEditBannerModal(item){
      this.typeBanner = "Edit"
      this.addBannerModel = JSON.parse(JSON.stringify(item));
      this.addBannerDialog = true;
    },
    openEditBanner(item){
      if(item.sub_type === 'modal'){
        this.openEditBannerModal(item);
      }
      if(item.sub_type === 'intro'){
        this.openEditIntro(item);
      }
      if(item.sub_type === 'bottom'){
        this.openEditBottom(item);
      }
    },
    formatDateForMoment(date){
      const day = date.slice(0,2);
      const month = date.slice(3,5);
      const year = date.slice(6);
      return `${year}-${month}-${day}`;
    },
    changeTypeAdd(val){
      this.addBannerModel.type = val+1;
    },
    indexArr(times){
      return times.time_start.reduce((acc,item,index) => {
        acc.push(index)
        return acc
      },[])
    },
    showPopupDelete(banner){
      this.deleteBanner = JSON.parse(JSON.stringify(banner));
      this.$refs.PopupDelete.open();
    },
    async BannerDeleteConfirm(){
      if(this.deleteBanner.sub_type === 'modal'){
        await this.deleteOneBanner(this.deleteBanner.id);
      }
      if(this.deleteBanner.sub_type === 'intro'){
        await this.deleteIntroBanner(this.deleteBanner.id)
      }
      if(this.deleteBanner.sub_type === 'bottom'){
        await this.deleteBottomBanner(this.deleteBanner.id);
      }
      this.successMessage = 'You banner deleted successfully!';
      this.$refs.PopupSuccess.open();
      this.deleteBanner = null;
    },
    async changeActive(item){
      const banner = JSON.parse(JSON.stringify(item))
      if(banner.is_active){
        banner.is_active = 0;
      }else{
        banner.is_active = 1;
      }
      if(item.sub_type === 'modal'){
        await this.editBanner(banner);
      }
      if(item.sub_type === 'intro'){
        await this.putIntroBanner(banner);
      }
      if(item.sub_type === 'bottom'){
        await this.putBottomBanner(banner);
      }
      this.successMessage = `Your banner is ${banner.is_active?'active':'inactive'} now!`
      this.$refs.PopupSuccess.open();
    },
    clearFile(banner){
      banner.video = null;
    },
    deletePeriod(times,index){
      times.time_start.splice(index,1);
      times.time_end.splice(index,1);
    },
    addPeriod(day){
      const defaultItemTime = {
        HH:"00",
        mm:"00"
      }
      day.time_start.push(defaultItemTime);
      day.time_end.push(defaultItemTime);
    },
    changeDatesEdit() {
      if (this.editBannerModel.dates[0] && this.editBannerModel.dates[1]) {
        if (this.editBannerModel.dates[0] > this.editBannerModel.dates[1]) {
          let start = this.editBannerModel.dates[0];
          let end = this.editBannerModel.dates[1];
          this.editBannerModel.dates[0] = end;
          this.editBannerModel.dates[1] = start;
        }
      }
      this.dateRangeTextEdit();
    },
    dateRangeTextEdit() {
      let date_start = null;
      let date_end = null;
      if (this.editBannerModel.dates[0] && this.editBannerModel.dates[1]) {
        date_start = this.$moment(this.editBannerModel.dates[0]).format("DD.MM.YYYY");
        date_end = this.$moment(this.editBannerModel.dates[1]).format("DD.MM.YYYY");
      } else if (this.editBannerModel.dates[0]) {
        date_start = this.$moment(this.editBannerModel.dates[0]).format("DD.MM.YYYY");
      } else if (this.editBannerModel.dates[1]) {
        date_end = this.$moment(this.editBannerModel.dates[1]).format("DD.MM.YYYY");
      } else {
        this.editBannerModel.date_model = "";
      }
      this.editBannerModel.date_from = date_start;
      this.editBannerModel.date_to = date_end;
      this.editBannerModel.date_model = [date_start, date_end].join(" - ");
    },
    changeDates() {
      if (this.addBannerModel.dates[0] && this.addBannerModel.dates[1]) {
        if (this.addBannerModel.dates[0] > this.addBannerModel.dates[1]) {
          let start = this.addBannerModel.dates[0];
          let end = this.addBannerModel.dates[1];
          this.addBannerModel.dates[0] = end;
          this.addBannerModel.dates[1] = start;
        }
      }
      this.dateRangeText();
    },
    dateRangeText() {
      let date_start = null;
      let date_end = null;
      if (this.addBannerModel.dates[0] && this.addBannerModel.dates[1]) {
        date_start = this.$moment(this.addBannerModel.dates[0]).format("DD.MM.YYYY");
        date_end = this.$moment(this.addBannerModel.dates[1]).format("DD.MM.YYYY");
      } else if (this.addBannerModel.dates[0]) {
        date_start = this.$moment(this.addBannerModel.dates[0]).format("DD.MM.YYYY");
      } else if (this.addBannerModel.dates[1]) {
        date_end = this.$moment(this.addBannerModel.dates[1]).format("DD.MM.YYYY");
      } else {
        this.addBannerModel.date_model = "";
      }
      this.addBannerModel.date_from = date_start;
      this.addBannerModel.date_to = date_end;
      this.addBannerModel.date_model = [date_start, date_end].join(" - ");
    },
    async onFileChange(file){
      try{
        let formData = new FormData()
        formData.append('lending_settings_id',this.landing.settings_id);
        formData.append('image',file);
        formData.append('file',file);
        const response = await this.$axios.post('upload-image',formData);
        this.addBannerModel.video = response.data;
      }catch(e){
        console.log(e);
      }
    },
    handleRemove(){
      this.addBannerModel.image  = null;
    },
    handleEditRemove(){
      this.editBannerModel.image = null;
    },
    handleSuccess(file){
      this.addBannerModel.image = file.xhr.response;
    },
    handleEditSuccess(file){
      this.editBannerModel.image = file.xhr.response;
    },
    customEvent(_file, xhr,formData){
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append('image',_file);
      formData.append('lending_settings_id',this.landing.settings_id);
    },
    openAddBanner(){
      this.typeBanner = "Create";
      this.addBannerModel = JSON.parse(JSON.stringify(this.defaultBannerModel))
      this.checkBannerType = false;
      this.addBannerDialog = true;
    },
    closeAddBannerDialog(){
      this.addBannerDialog = false;
    },
    async changeHotel(){
      await this.getBannerList(this.objectItem.id);
      await this.getIntroBanners(this.objectItem.id);
      await this.getBottomBanners(this.objectItem.id);
      await store.dispatch("landing/getHotelLangs", this.objectItem.id);
      await store.dispatch("landing/getSettingsId", this.objectItem.id);
      await this.getRoomCategories(this.objectItem.id);
      for(let i = 0;i<this.selected_langs.length;i++){
        this.defaultIntroBanner.image[this.selected_langs[i]] = `${process.env.VUE_APP_LINK}/banner_default.jpg`;
        this.defaultIntroBanner.video[this.selected_langs[i]] = null;
        this.defaultIntroBanner.pro[this.selected_langs[i]] = JSON.parse(JSON.stringify(this.addIntroBannerModel.pro['en']));
        this.defaultBottomBannerModel.video[this.selected_langs[i]] = null
        this.defaultBottomBannerModel.image[this.selected_langs[i]] = `${process.env.VUE_APP_LINK}/banner_default.jpg`;
        this.defaultBottomBannerModel.link[this.selected_langs[i]] = '';
        this.defaultBottomBannerModel.video_link[this.selected_langs[i]] = '';
        this.defaultBannerModel.video[this.selected_langs[i]] = null
        this.defaultBannerModel.image[this.selected_langs[i]] = `${process.env.VUE_APP_LINK}/banner_default.jpg`;
        this.defaultBannerModel.link[this.selected_langs[i]] = '';
        this.defaultBannerModel.video_link[this.selected_langs[i]] = '';
      }
    },
  },
  async mounted(){
    this.$store.commit('landing/setSelectedLangs',['en']);
    if (this.objects.list.length) {
      if (this.$route.query.id) {
        const candidate = this.objects.list.find(
            (x) => x.id === +this.$route.query.id
        );
        if (candidate) {
          this.objectItem = candidate;
        } else {
          this.objectItem = this.objects.list[0];
        }
      } else {
        this.objectItem = this.objects.list[0];
      }
      await this.getBannerList(this.objectItem.id);
      await this.getIntroBanners(this.objectItem.id);
      await this.getBottomBanners(this.objectItem.id);
      await store.dispatch("landing/getHotelLangs", this.objectItem.id);
      for(let i = 0;i<this.selected_langs.length;i++){
        this.defaultIntroBanner.image[this.selected_langs[i]] = `${process.env.VUE_APP_LINK}/banner_default.jpg`;
        this.defaultIntroBanner.video[this.selected_langs[i]] = null;
        this.defaultIntroBanner.pro[this.selected_langs[i]] = JSON.parse(JSON.stringify(this.addIntroBannerModel.pro['en']));
        this.defaultBottomBannerModel.video[this.selected_langs[i]] = null
        this.defaultBottomBannerModel.image[this.selected_langs[i]] = `${process.env.VUE_APP_LINK}/banner_default.jpg`;
        this.defaultBottomBannerModel.link[this.selected_langs[i]] = '';
        this.defaultBottomBannerModel.video_link[this.selected_langs[i]] = '';
        this.defaultBannerModel.video[this.selected_langs[i]] = null
        this.defaultBannerModel.image[this.selected_langs[i]] = `${process.env.VUE_APP_LINK}/banner_default.jpg`;
        this.defaultBannerModel.link[this.selected_langs[i]] = '';
        this.defaultBannerModel.video_link[this.selected_langs[i]] = '';
      }
      await store.dispatch("landing/getSettingsId", this.objectItem.id);
      await this.getRoomCategories(this.objectItem.id);
    }
  }
}
</script>

<style scoped lang="scss">
.color{
  cursor: pointer;
  height: 30px;
  width: 30px;
  border: 1px solid #000;
  border-radius: 4px;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: steelblue;
}
.play-video{
  cursor: pointer;
  -webkit-mask-image: url(~@/assets/icons/play_button.svg);
  mask-image: url(~@/assets/icons/play_button.svg);
  background-color:var(--color);
  position: absolute;
  &:hover{
    background-color:var(--color-hover);
  }
  &_with_img{
    position: absolute;
    top:150px;
    left:calc(50% - 40px);
  }
}
.dropzone .dz-preview:hover .dz-details{
  opacity: 0;
}
.cropper-bg{
  background-repeat: repeat;
}
.tariff_obj {
  margin-left: 5px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: #007db5;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
</style>
